<template>
  <div
    class="mega-menu"
    :class="classes">
    <div
      ref="content"
      class="mega-menu__content">
      <slot />
    </div>
    <div
      class="mega-menu__backdrop"
      :style="style" />
  </div>
</template>

<script>
export default {
  name: "MegaMenu",
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ( {
    height: 0,
  } ),
  computed: {
    classes () {
      const result = [];

      if ( this.fullWidth ) {
        result.push( "mega-menu--full-width" );
      }

      return result;
    },
    style () {
      return {
        height: `${this.height}px`,
      };
    },
  },
  mounted () {
    this.calculateHeight();
  },
  methods: {
    calculateHeight () {
      const page = document.body;
      const pageHeader = document.querySelector( "header.page-header" );
      const { firstElementChild: content } = this.$refs.content;

      // offset = distance of menu item from top of doc
      const offset = content.getBoundingClientRect().top + page.scrollTop;
      // heightPlusOffset = height of menu content plus offset
      const heightPlusOffset = content.clientHeight + offset;

      // height = overall header + menu content height, minus the header
      this.height = heightPlusOffset - pageHeader.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.mega-menu {
  animation: fadein 0.2s;

  &__content {
    position: relative;
    z-index: 200;
    height: 0;
  }

  &__backdrop {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 100px;
    background: #333;
    box-shadow: 0 15px 15px rgb( 33 33 33 / 25% );
  }

  @keyframes fadein {
    from { opacity: 0; }

    to { opacity: 1; }
  }

  &--full-width {
    .mega-menu__content {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
    }
  }
}
</style>
