<template>
  <div
    class="default-layout"
    :class="{'default-layout--show-bar': !indicator.isLoading.value}">
    <apollo-announcements
      align-buttons="end"
      button-look="default" />

    <div class="default-layout__page">
      <SiteHeader
        :content="home.data.value"
        :international="international" />

      <div>
        <slot />
      </div>

      <SiteFooter
        :content="home.data.value"
        :international="international" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useLoadingIndicator from "~/composables/useLoadingIndicator";
import { duration, throttle } from "~/utils/loadingIndicator";
import { useContentDeliveryResource, useCurrentCulture } from "#imports";
import type { Home } from "~/models/generated/Home";

const contentResource = useContentDeliveryResource();
const home = await contentResource.getByTypeSingle<Home>( "home" );

const culture = useCurrentCulture();

const international = computed( () => `${culture.value}`.toLowerCase() !== "en-gb" );

const indicator = useLoadingIndicator( {
  duration,
  throttle,
} );

const nuxtApp = useNuxtApp();
nuxtApp.hook( "page:start", indicator.start );
nuxtApp.hook( "page:finish", indicator.finish );
nuxtApp.hook( "vue:error", indicator.finish );
onBeforeUnmount( indicator.finish );

</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: max( 100vh, 800px );
  border-top: 2px solid #333;

  &--show-bar {
    border-top-color: var( --color-primary-500 );
  }

  &__page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    flex-grow: 1;
  }
}
</style>
