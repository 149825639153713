export default function useLoadingIndicator ( opts: {
  duration: number,
  throttle: number
} ) {
  const progress = ref( 0 );
  const isLoading = ref( false );
  const step = computed( () => 10000 / opts.duration );

  let timer: any = null;
  let throttle: any = null;

  function clear () {
    clearInterval( timer );
    clearTimeout( throttle );
    timer = null;
    throttle = null;
  }

  function increase ( num: number ) {
    progress.value = Math.min( 100, progress.value + num );
  }

  function hide () {
    clear();
    if ( process.client ) {
      setTimeout( () => {
        isLoading.value = false;
        setTimeout( () => { progress.value = 0; }, 400 );
      }, 500 );
    }
  }

  function startTimer () {
    if ( process.client ) {
      timer = setInterval( () => { increase( step.value ); }, 100 );
    }
  }

  function finish () {
    progress.value = 100;
    hide();
  }

  function start () {
    clear();
    progress.value = 0;
    if ( opts.throttle && process.client ) {
      throttle = setTimeout( () => {
        isLoading.value = true;
        startTimer();
      }, opts.throttle );
    } else {
      isLoading.value = true;
      startTimer();
    }
  }

  return {
    progress,
    isLoading,
    start,
    finish,
    clear,
  };
}
